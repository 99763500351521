import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaInfoCircle, FaHandPointRight } from "react-icons/fa";


const NoCondominio = () => {
  const navigate = useNavigate()

  // mudar titulo da página
  useEffect(() => {
    document.title = 'Abrir Garagem'

    // scroll to top, smooth behavior
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
  <section id="sobre">
    <h1>Abrir Garagem</h1>

    <div className="no-condominio">
      <FaInfoCircle />
      <div>
        Oooops! Parece que você ainda não faz parte de nenhum condomínio.
      </div>
    </div>

    <div className="no-condominio">
      <FaHandPointRight />
      <div>
        Se seu condomínio já usa o Abrir Garagem, peça ao síndico para lhe adicionar.
      </div>
    </div>

    <p><b>Abrir Garagem</b> é um projeto da Automatizando.dev, que visa facilitar a vida de moradores de casas ou condomínios, permitindo que eles abram o portão da garagem de forma rápida e segura, usando apenas o celular.</p>

    <p>Com o Abrir Garagem, você não precisa mais se preocupar em ter que carregar o controle remoto do portão, ou ter que esperar o porteiro abrir o portão para você entrar. Basta abrir o app, clicar no botão e o portão abre automaticamente.</p>

    <button
      className="dashboard-button green-button"
      style={{ padding: '20px', fontSize: '1.1rem' }}
      onClick={() => navigate('/sobre')}
    >Quero saber mais!</button>

  </section>
  )
}

export default NoCondominio
