/*
 * ./context/AppContext/index.js
 */
import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { GetToken } from '../../utils'

export const GlobalContext = createContext()
export const NotificacoesContext = createContext()

// global object
const globalAppData = {
  appName: 'Automatizando.dev',
  token: GetToken(),
  // token: '',
  banner_mestre_sabe: '/img/banner-mestre-sabe-dark.png',
  userData: {
    id: '',
    username: '',
    nome: '',
    email: '',
    password: '',
    newsletter: false,
    tipo: '',
    foto: '',
    website: '',
    twitter: '',
    facebook: '',
    linkedln: '',
    youtube: '',
    instagram: '',
    headline: '',
    biografia: '',
    show_profile: false,
    show_courses: false,
  },
  language: 'pt_BR',
  isPostsLoaded: false,
  isUserPostsLoaded: false,
  isAdminPostsLoaded: false,
  sidebarOpen: true,
  tags: [],
  posts: [],
  topics: [],
  // language: window.navigator.language || 'pt_BR'
}

const globalNotificacoesData = []

// AppContext component, provide object data globally
const AppContext = ({ children }) => {
  const [appData, setAppData] = useState(globalAppData)
  const [notificacoesData, setNotificacoesData] = useState(globalNotificacoesData)

  return (
    <GlobalContext.Provider value={{ appData, setAppData }}>
      <NotificacoesContext.Provider value={{ notificacoesData, setNotificacoesData }}>
        { children }
      </NotificacoesContext.Provider>
    </GlobalContext.Provider>
  )
}

AppContext.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppContext
