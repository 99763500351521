/*
 * ./components/Common/index.jsx
 *
 * collection of common components to use in the app
 */
import React, { useCallback, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'

// imports do skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// imports do componense ImageUpload
import { useDropzone } from 'react-dropzone'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

// Toastify
import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

// imports do componente PopperHelp
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'

// import do confirm-alert
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

// image modal
import ModalImage from "react-modal-image"

// imports dos ícones
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp, FaLink } from 'react-icons/fa'

// react-spinners
import { BarLoader } from 'react-spinners'

import { IsMobile } from '../../utils'

const ImageUpload = ({ onFileChange }) => {
  const onDrop = useCallback((acceptedFiles) => {
    onFileChange(acceptedFiles[0])
  }, [onFileChange])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input 
        id="image-upload" 
        accept="image/*"
        {...getInputProps()} 
        onClick={(e) => {
          e.preventDefault()
          document.getElementById('image-upload').value = null
        }}
      />
      {
        isDragActive ? (
          <p>Arraste ou solte sua imagem aqui...</p>
        ) : (
          <p>Clique ou arraste e solte sua imagem para fazer o upload. Duplo clique para aceitar o corte.</p>
        )
      }
    </div>
  )
}

ImageUpload.propTypes = {
  onFileChange: PropTypes.func.isRequired,
}

const ImageCropper = ({ src, onCrop, onFinishCrop, onCancel }) => {
  const cropperRef = useRef(null)
  const finishedCrop = useCallback(() => {
    onFinishCrop()
  }, [onFinishCrop])

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper
    const croppedImageBlob = cropper.getCroppedCanvas().toDataURL()
    onCrop(croppedImageBlob)
  }

  return (
    <div 
      style={{ position: 'relative' }}
    >
      <Cropper
        ref={cropperRef}
        src={src}
        style={{ height: 400, width: '100%' }}
        aspectRatio={1}
        guides={false}
        crop={handleCrop}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          padding: '5px',
          borderRadius: '3px',
          fontSize: '14px',
          maxWidth: '250px',
        }}
      >
        <button
          style={{ 
            marginBottom: '5px',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            finishedCrop()
          }}
        >
          Confirmar
        </button>
        <button 
          style={{ 
            marginBottom: '5px',
            width: '100%',
            padding: '10px',
          }}
          onClick={onCancel}
        >
          Cancelar
        </button>
      </div>
    </div>
  )
}

ImageCropper.propTypes = {
  src: PropTypes.string.isRequired,
  onCrop: PropTypes.func.isRequired,
  onFinishCrop: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const Skelet = ({ children, ...props }) => {
  return (
    <Skeleton {...props}>
      {children}
    </Skeleton>
  )
}

Skelet.propTypes = {
  children: PropTypes.node,
}


const PopperHelp = ({ children, ...props }) => {
  return (
    <Popper {...props}>
      <Box className="popper-box">
        {children}
      </Box>
    </Popper>
  )
}

PopperHelp.propTypes = {
  children: PropTypes.node,
}

const ImageRenderer = ({ children }) => {
  const [ src, alt ] = children[0].trim().split("|")
  const imageSrc = `https://static.automatizando.dev/anexos/${src}`

      // <img src={`https://static.automatizando.dev/anexos/${src}`} alt={alt} title={alt} />
  return (
    <div className="image-container">
      <ModalImage
        className="post-image"
        small={imageSrc}
        large={imageSrc}
        alt={alt}
        hideZoom={true}
      />
      { alt && <p>{alt}</p> }
    </div>
  )
}

ImageRenderer.propTypes = {
  children: PropTypes.node,
}

const Breadcrumb = ({ title, paths = ['/'], appData }) => {
  const newPaths = [...paths]
  newPaths.push(title)

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const labels = {
    "/": "Home",
    "/posts": "Posts",
    "/admin/posts": "Admin Posts",
    "/forum": "Fórum",
    "/dashboard/user": `Perfil de ${appData?.userData?.username || 'usuário'}`,
  }

  const renderBreadcrumbItems = () => {
    // add title to last path
    return newPaths.map((path, index) => {
      // verificar se path possui ":::", se sim a primeira parte é o path e a segunda é o label
      let name
      if (path.includes(':::')) {
        [path, name] = path.split(':::')
      } else {
        name = labels[path] || capitalize(path.split('/').pop())
      }

      return (
        <ul className="breadcrumb-list" key={index}>
          { // if is last item, don't render link
            index === newPaths.length - 1 
              ? <li className="breadcrumb-item breadcrumb-item-last">
                <h1>{title}</h1>
              </li>
              : <li className="breadcrumb-item">
                <NavLink to={path}>{name}</NavLink>
              </li>
          }
        </ul>
      )
    })
  }

  return (
    <div className="breadcrumb-container">
      {renderBreadcrumbItems()}
    </div>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  paths: PropTypes.arrayOf(PropTypes.string),
  appData: PropTypes.object,
}

const EmbedReel = ({ reelCode }) => {
  const embedUrl = `https://www.instagram.com/reel/${reelCode}/embed`

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe
        src={embedUrl}
        width="540"
        height="600"
        frameBorder="0"
        scrolling="no"
        title={`Instagram Reel ${reelCode}`}
      ></iframe>
    </div>
  )
}

EmbedReel.propTypes = {
  reelCode: PropTypes.string.isRequired,
}

const SocialBar = ({ url }) => {
  const currentUrl = url || window.location.href

  const handleCopyLink = () => {
    copy(currentUrl)
    toast.success('Link copiado com sucesso!')
  }

  const createShareUrl = (network) => {
    const encodedUrl = encodeURIComponent(currentUrl)

    switch (network) {
      case 'facebook':
        return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
      case 'twitter':
        return `https://twitter.com/intent/tweet?url=${encodedUrl}`
      case 'linkedin':
        return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`
      case 'whatsapp':
        return `https://wa.me/?text=${encodedUrl}`
      default:
        return '#'
    }
  }

  const openShareWindow = (shareUrl, width = 500, height = 500) => {
    const left = (window.innerWidth - width) / 2
    const top = (window.innerHeight - height) / 2
    window.open(
      shareUrl,
      "_blank",
      `toolbar=no,menubar=no,resizable=yes,scrollbars=yes,width=${width},height=${height},left=${left},top=${top}`
    )
  }

  return (
    <div className="social-bar">
      <FaLink onClick={handleCopyLink} title="Copiar link" />
      <FaFacebookF onClick={() => openShareWindow(createShareUrl('facebook'))} title="Compartilhar no Facebook" />
      <FaTwitter onClick={() => openShareWindow(createShareUrl('twitter'))} title="Compartilhar no Twitter" />
      <FaLinkedinIn onClick={() => openShareWindow(createShareUrl('linkedin'))} title="Compartilhar no LinkedIn" />
      <a href={createShareUrl('whatsapp')} target="_blank" rel="noopener noreferrer" title="Compartilhar no WhatsApp">
        <FaWhatsapp />
      </a>
    </div>
  )
}

SocialBar.propTypes = {
  url: PropTypes.string,
}

SocialBar.defaultProps = {
  url: null,
}

const Toast = () => {
  const isMobile = IsMobile()

  return (
        <ToastContainer
          position={isMobile ? "top-center" : "top-center"}
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
  )
}

// teste de custom ui
const showConfirmAlert2 = (message, onConfirm) => {
  const fileName = message.match(/<strong>(.*?)<\/strong>/)[1]
  confirmAlert({
    customUI: ({ onClose }) => (
      <div className="custom-ui">
        <h1>Confirmação</h1>
        <p>
          Tem certeza que deseja remover o arquivo <strong>{fileName}</strong>?
        </p>
        <button onClick={onClose}>Não</button>
        <button
          onClick={() => {
            onConfirm()
            onClose()
          }}
        >
          Sim
        </button>
      </div>
    ),
  })
}

const showConfirmAlert = (message, onConfirm) => {
  confirmAlert({
    title: 'Confirmação',
    message,
    buttons: [
      {
        label: 'Sim',
        onClick: onConfirm,
      },
      {
        label: 'Não',
        onClick: () => {},
      },
    ],
  })
}

const Loading = ({ text = 'Carregando...' }) => {
  return (
    <div className="overlay-container">
      <BarLoader color="var(--color-blue-logo)" cssOverride={{ width: '300px' }} />
      <p>{text}</p>
    </div>
  )
}

Loading.propTypes = {
  text: PropTypes.string,
}

const loadAppData = async ({ appData, setAppData, postAPIData, force = 0 }) => {
  // verificar se appData.userData existe
  if (appData.userData && appData.userData.condominios && !force) {
    // setCondominios(appData.userData.condominios)
    // setSelectedCondominio(appData.userData.condominio_selected_id || appData.userData.condominios[0]?.id)
    return
  }

  const email = localStorage.getItem('email')
  if (!email) {
    console.log('Usuário não logado')
    return
  }

  const res = postAPIData('/dados_usuario', { email: localStorage.getItem('email') })
  res.then((data) => {
    if (data.id) {
      // atualizar appData.userData
      data.loaded = true
      setAppData((prev) => (
        { ...prev, userData: { 
          ...appData.userData, 
          ...data,
          // grab condominio_selected_id from localStorage
          condominio_selected_id: localStorage.getItem('condominio_selected_id') || data.condominio_selected_id || data.condominios[0]?.id
        }}
      ))

      // setCondominios(data.condominios)
      // setSelectedCondominio(appData.userData.condominios[0].id)
    } else {
      console.log('Erro ao carregar os dados do usuário. Tente novamente...')
    }
  })
    .catch((err) => {
      console.log('Erro ao carregar os dados do usuário. Tente novamente...')
    })
}

loadAppData.propTypes = {
  appData: PropTypes.object,
  setAppData: PropTypes.func,
  postAPIData: PropTypes.func,
}

const SelectCondominio = ({ appData, setAppData }) => {
  // return null if there is no condominios
  if (!appData || !appData.userData?.condominios || appData.userData?.condominios?.length === 0) {
    return null
  }

  const selectedCondominio = appData.userData.condominio_selected_id
  const condominios = appData.userData.condominios

  return condominios.length === 1 ? (
    <div className="garage-select">
      <span>{condominios[0].nome.length > 20 ? condominios[0].nome.substring(0, 20) + '...' : condominios[0].nome}</span>
    </div>
  ) : (
    <div className="garage-select">
      <select name="condominio" id="condominio" value={selectedCondominio} onChange={(e) => {
        setAppData((prev) => (
          { 
            ...prev, 
            isAdminUsersLoaded: false,
            userData: {
              ...appData.userData,
              condominio_selected_id: e.target.value
            }}
        ))

        // store selected condominio id in localStorage
        localStorage.setItem('condominio_selected_id', e.target.value)
      }}>
        {condominios.map((condominio, index) => (
          <option key={index} value={condominio.id}>{condominio.nome.length > 20 ? condominio.nome.substring(0, 20) + '...' : condominio.nome}</option>
        ))}
      </select>
    </div>
  )
}

SelectCondominio.propTypes = {
  appData: PropTypes.object,
  setAppData: PropTypes.func,
}

const BuscaCep = ({ setAppData, className = '', id, postAPIData, setOrder, currentCep = '' }) => {
  const [cep, setCep] = useState(currentCep)
  // endereco will have: logradouro, bairro, localidade, uf

  const buscarEndereco = async (cep) => {
    try {
      const obj = await postAPIData('/common/buscar-cep', { cep })
      const order = {
        cep,
        endereco: obj.data.logradouro,
        bairro: obj.data.bairro,
        cidade: obj.data.localidade,
        uf: obj.data.uf,
      }

      setAppData((prev) => (
        { 
          ...prev, 
          order: {
            ...prev.order,
            ...order
          }
        }
      ))

      setOrder((prev) => (
        {
          ...prev,
          ...order
        }
      ))
    } catch (error) {
      console.error('Erro na busca do CEP:', error)
    }
  }

  const handleChange = (e) => {
    // only numbers
    if (isNaN(e.target.value)) {
      return
    }

    e.target.classList.remove("is-invalid")
    setCep(e.target.value)

    // if length is 8, search for address
    if (e.target.value.length === 8) {
      buscarEndereco(e.target.value)
    }
  }

  return (
      <input 
        type="text" 
        value={cep} 
        id={id}
        onChange={handleChange}
        className={className}
        placeholder="Digite o CEP (apenas números)"
      />
  )
}

BuscaCep.propTypes = {
  setAppData: PropTypes.func,
  setOrder: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  postAPIData: PropTypes.func,
  currentCep: PropTypes.string,
}

export { 
  ImageUpload,
  ImageCropper,
  PopperHelp,
  Breadcrumb,
  SocialBar,
  Toast,
  Skelet,
  showConfirmAlert,
  showConfirmAlert2,
  Loading,
  loadAppData,
  SelectCondominio,
  BuscaCep,
}
