import React from 'react'
import PropTypes from 'prop-types'

// imports dos ícones
import { MdOutlineHelp, MdOutlineModeEditOutline, MdOutlinePowerSettingsNew, MdDelete, MdCameraAlt, MdKeyboardArrowDown, MdOutlineLogout, MdOutlineClose, MdExpandLess, MdExpandMore, MdFileUpload, MdOutlineImageNotSupported, MdArticle, MdOutlineEmail, MdOutlineDoNotDisturb } from 'react-icons/md'
import { RxDashboard } from 'react-icons/rx'
import { FaUser, FaUsers, FaEye, FaEyeSlash, FaWhmcs, FaRobot, FaBook, FaHeadset, FaHashtag, FaSave, FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp, FaLink, FaGlobe, FaVideo, FaSearch, FaInfo, FaDownload, FaRegCheckCircle } from 'react-icons/fa'
import { FaRegCircleXmark, FaDoorClosed } from "react-icons/fa6";
import { FiMessageSquare, FiChevronLeft } from 'react-icons/fi'
import { SiAmazonalexa } from 'react-icons/si'
import { SlUserFollow, SlUserUnfollow, SlUserFollowing } from 'react-icons/sl'
import { TfiWrite } from 'react-icons/tfi'
import { RxLockClosed, RxDot, RxDotFilled } from 'react-icons/rx'
// import { GoArrowUp, GoArrowDown } from 'react-icons/go'
import { ImArrowUp, ImArrowDown } from 'react-icons/im'
import { RiFileCopy2Line, RiVideoUploadFill } from 'react-icons/ri'
import { IoIosClose } from 'react-icons/io'
import { AiOutlineSchedule } from "react-icons/ai";
import { BsCaretDownFill, BsFillImageFill, BsPinAngleFill, BsSnow } from 'react-icons/bs'
import { HiOutlineDotsVertical, HiOutlineDocumentText, HiMenu } from 'react-icons/hi'
import { VscClearAll } from 'react-icons/vsc'
import { PiBellLight, PiBellSlashLight, PiEnvelopeSimpleLight, PiBabyBold } from 'react-icons/pi'
// import { CiUser } from 'react-icons/ci'
import { GiDuck, GiHomeGarage } from 'react-icons/gi'
import { GrNext, GrPrevious } from 'react-icons/gr'

// FaChildren, FaHorse, PiBabyBold
// recebe children e props
const IconHelp = ({ children, ...props }) => {
  return (
    <MdOutlineHelp {...props}>
      {children}
    </MdOutlineHelp>
  )
}

IconHelp.propTypes = {
  children: PropTypes.node,
}

const IconEdit = ({ children, ...props }) => {
  return (
    <MdOutlineModeEditOutline {...props}>
      {children}
    </MdOutlineModeEditOutline>
  )
}

IconEdit.propTypes = {
  children: PropTypes.node,
}

const IconDelete = ({ children, ...props }) => {
  return (
    <MdDelete {...props}>
      {children}
    </MdDelete>
  )
}

IconDelete.propTypes = {
  children: PropTypes.node,
}

const IconOnOff = ({ children, ...props }) => {
  return (
    <MdOutlinePowerSettingsNew {...props}>
      {children}
    </MdOutlinePowerSettingsNew>
  )
}

IconOnOff.propTypes = {
  children: PropTypes.node,
}

const IconCamera = ({ children, ...props }) => {
  return (
    <MdCameraAlt {...props}>
      {children}
    </MdCameraAlt>
  )
}

IconCamera.propTypes = {
  children: PropTypes.node,
}

const IconNotificacao = ({ children, ...props }) => {
  return (
    <PiBellLight {...props}>
      {children}
    </PiBellLight>
  )
}

IconNotificacao.propTypes = {
  children: PropTypes.node,
}

const IconNotificacaoOff = ({ children, ...props }) => {
  return (
    <PiBellSlashLight {...props}>
      {children}
    </PiBellSlashLight>
  )
}

IconNotificacaoOff.propTypes = {
  children: PropTypes.node,
}

const IconDown = ({ children, ...props }) => {
  return (
    <MdKeyboardArrowDown {...props}>
      {children}
    </MdKeyboardArrowDown>
  )
}

IconDown.propTypes = {
  children: PropTypes.node,
}

const IconDashboard = ({ children, ...props }) => {
  return (
    <RxDashboard {...props}>
      {children}
    </RxDashboard>
  )
}

IconDashboard.propTypes = {
  children: PropTypes.node,
}

const IconSettings = ({ children, ...props }) => {
  return (
    <FaWhmcs {...props}>
      {children}
    </FaWhmcs>
  )
}

IconSettings.propTypes = {
  children: PropTypes.node,
}

const IconUser = ({ children, ...props }) => {
  return (
    <FaUser {...props}>
      {children}
    </FaUser>
  )
}

IconUser.propTypes = {
  children: PropTypes.node,
}

const IconUsers = ({ children, ...props }) => {
  return (
    <FaUsers {...props}>
      {children}
    </FaUsers>
  )
}

IconUsers.propTypes = {
  children: PropTypes.node,
}

const IconEye = ({ children, ...props }) => {
  return (
    <FaEye {...props}>
      {children}
    </FaEye>
  )
}

IconEye.propTypes = {
  children: PropTypes.node,
}

const IconEyeSlash = ({ children, ...props }) => {
  return (
    <FaEyeSlash {...props}>
      {children}
    </FaEyeSlash>
  )
}

IconEyeSlash.propTypes = {
  children: PropTypes.node,
}

const IconRobot = ({ children, ...props }) => {
  return (
    <FaRobot {...props}>
      {children}
    </FaRobot>
  )
}

IconRobot.propTypes = {
  children: PropTypes.node,
}

const IconBook = ({ children, ...props }) => {
  return (
    <FaBook {...props}>
      {children}
    </FaBook>
  )
}

IconBook.propTypes = {
  children: PropTypes.node,
}

const IconHeadset = ({ children, ...props }) => {
  return (
    <FaHeadset {...props}>
      {children}
    </FaHeadset>
  )
}

IconHeadset.propTypes = {
  children: PropTypes.node,
}

const IconAlexa = ({ children, ...props }) => {
  return (
    <SiAmazonalexa {...props}>
      {children}
    </SiAmazonalexa>
  )
}

IconAlexa.propTypes = {
  children: PropTypes.node,
}

const IconWrite = ({ children, ...props }) => {
  return (
    <TfiWrite {...props}>
      {children}
    </TfiWrite>
  )
}

IconWrite.propTypes = {
  children: PropTypes.node,
}

const IconLogout = ({ children, ...props }) => {
  return (
    <MdOutlineLogout {...props}>
      {children}
    </MdOutlineLogout>
  )
}

IconLogout.propTypes = {
  children: PropTypes.node,
}

const IconPassword = ({ children, ...props }) => {
  return (
    <RxLockClosed {...props}>
      {children}
    </RxLockClosed>
  )
}

IconPassword.propTypes = {
  children: PropTypes.node,
}

const IconArrowUp = ({ children, ...props }) => {
  return (
    <ImArrowUp {...props}>
      {children}
    </ImArrowUp>
  )
}

IconArrowUp.propTypes = {
  children: PropTypes.node,
}

const IconArrowDown = ({ children, ...props }) => {
  return (
    <ImArrowDown {...props}>
      {children}
    </ImArrowDown>
  )
}

IconArrowDown.propTypes = {
  children: PropTypes.node,
}

const IconDot = ({ children, ...props }) => {
  return (
    <RxDot {...props}>
      {children}
    </RxDot>
  )
}

IconDot.propTypes = {
  children: PropTypes.node,
}

const IconDotFilled = ({ children, ...props }) => {
  return (
    <RxDotFilled {...props}>
      {children}
    </RxDotFilled>
  )
}

IconDotFilled.propTypes = {
  children: PropTypes.node,
}

const IconReply = ({ children, ...props }) => {
  return (
    <FiMessageSquare {...props}>
      {children}
    </FiMessageSquare>
  )
}

IconReply.propTypes = {
  children: PropTypes.node,
}

const IconHashtag = ({ children, ...props }) => {
  return (
    <FaHashtag {...props}>
      {children}
    </FaHashtag>
  )
}

IconHashtag.propTypes = {
  children: PropTypes.node,
}

const IconSave = ({ children, ...props }) => {
  return (
    <FaSave {...props}>
      {children}
    </FaSave>
  )
}

IconSave.propTypes = {
  children: PropTypes.node,
}

const IconCancel = ({ children, ...props }) => {
  return (
    <MdOutlineClose {...props}>
      {children}
    </MdOutlineClose>
  )
}

IconCancel.propTypes = {
  children: PropTypes.node,
}

const IconExpand = ({ children, ...props }) => {
  return (
    <MdExpandMore {...props}>
      {children}
    </MdExpandMore>
  )
}

IconExpand.propTypes = {
  children: PropTypes.node,
}

const IconCollapse = ({ children, ...props }) => {
  return (
    <MdExpandLess {...props}>
      {children}
    </MdExpandLess>
  )
}

IconCollapse.propTypes = {
  children: PropTypes.node,
}

const IconCopy = ({ children, ...props }) => {
  return (
    <RiFileCopy2Line {...props}>
      {children}
    </RiFileCopy2Line>
  )
}

IconCopy.propTypes = {
  children: PropTypes.node,
}

const IconUpload = ({ children, ...props }) => {
  return (
    <MdFileUpload {...props}>
      {children}
    </MdFileUpload>
  )
}

IconUpload.propTypes = {
  children: PropTypes.node,
}

const IconUploadImage = ({ children, ...props }) => {
  return (
    <BsFillImageFill {...props}>
      {children}
    </BsFillImageFill>
  )
}

IconUploadImage.propTypes = {
  children: PropTypes.node,
}

const IconFollow = ({ children, ...props }) => {
  return (
    <SlUserFollow {...props}>
      {children}
    </SlUserFollow>
  )
}

IconFollow.propTypes = {
  children: PropTypes.node,
}

const IconUnfollow = ({ children, ...props }) => {
  return (
    <SlUserUnfollow {...props}>
      {children}
    </SlUserUnfollow>
  )
}

IconUnfollow.propTypes = {
  children: PropTypes.node,
}

const IconFollowing = ({ children, ...props }) => {
  return (
    <SlUserFollowing {...props}>
      {children}
    </SlUserFollowing>
  )
}

IconFollowing.propTypes = {
  children: PropTypes.node,
}

const IconFacebook = ({ children, ...props }) => {
  return (
    <FaFacebookF {...props}>
      {children}
    </FaFacebookF>
  )
}

IconFacebook.propTypes = {
  children: PropTypes.node,
}

const IconTwitter = ({ children, ...props }) => {
  return (
    <FaTwitter {...props}>
      {children}
    </FaTwitter>
  )
}

IconTwitter.propTypes = {
  children: PropTypes.node,
}

const IconLinkedin = ({ children, ...props }) => {
  return (
    <FaLinkedinIn {...props}>
      {children}
    </FaLinkedinIn>
  )
}

IconLinkedin.propTypes = {
  children: PropTypes.node,
}

const IconWhatsapp = ({ children, ...props }) => {
  return (
    <FaWhatsapp {...props}>
      {children}
    </FaWhatsapp>
  )
}

IconWhatsapp.propTypes = {
  children: PropTypes.node,
}

const IconLink = ({ children, ...props }) => {
  return (
    <FaLink {...props}>
      {children}
    </FaLink>
  )
}

IconLink.propTypes = {
  children: PropTypes.node,
}

const IconWeb = ({ children, ...props }) => {
  return (
    <FaGlobe {...props}>
      {children}
    </FaGlobe>
  )
}

IconWeb.propTypes = {
  children: PropTypes.node,
}

const IconZerarFoto = ({ children, ...props }) => {
  return (
    <MdOutlineImageNotSupported {...props}>
      {children}
    </MdOutlineImageNotSupported>
  )
}

IconZerarFoto.propTypes = {
  children: PropTypes.node,
}

const IconVideo = ({ children, ...props }) => {
  return (
    <FaVideo {...props}>
      {children}
    </FaVideo>
  )
}

IconVideo.propTypes = {
  children: PropTypes.node,
}

const IconUploadVideo = ({ children, ...props }) => {
  return (
    <RiVideoUploadFill {...props}>
      {children}
    </RiVideoUploadFill>
  )
}

IconUploadVideo.propTypes = {
  children: PropTypes.node,
}

const IconSidebarOpen = ({ children, ...props }) => {
  return (
    <BsCaretDownFill {...props}>
      {children}
    </BsCaretDownFill>
  )
}

IconSidebarOpen.propTypes = {
  children: PropTypes.node,
}

const IconSidebarClose = ({ children, ...props }) => {
  return (
    <BsCaretDownFill {...props}>
      {children}
    </BsCaretDownFill>
  )
}

IconSidebarClose.propTypes = {
  children: PropTypes.node,
}

const IconClose = ({ children, ...props }) => {
  return (
    <IoIosClose {...props}>
      {children}
    </IoIosClose>
  )
}

IconClose.propTypes = {
  children: PropTypes.node,
}

const IconArticle = ({ children, ...props }) => {
  return (
    <MdArticle {...props}>
      {children}
    </MdArticle>
  )
}

IconArticle.propTypes = {
  children: PropTypes.node,
}

const IconVerticalDots = ({ children, ...props }) => {
  return (
    <HiOutlineDotsVertical {...props}>
      {children}
    </HiOutlineDotsVertical>
  )
}

IconVerticalDots.propTypes = {
  children: PropTypes.node,
}

const IconClear = ({ children, ...props }) => {
  return (
    <VscClearAll {...props}>
      {children}
    </VscClearAll>
  )
}

IconClear.propTypes = {
  children: PropTypes.node,
}

const IconPatinho = ({ children, ...props }) => {
  return (
    <GiDuck {...props}>
      {children}
    </GiDuck>
  )
}

IconPatinho.propTypes = {
  children: PropTypes.node,
}

const IconFixed = ({ children, ...props }) => {
  return (
    <BsPinAngleFill {...props}>
      {children}
    </BsPinAngleFill>
  )
}

IconFixed.propTypes = {
  children: PropTypes.node,
}

const IconText = ({ children, ...props }) => {
  return (
    <HiOutlineDocumentText {...props}>
      {children}
    </HiOutlineDocumentText>
  )
}

IconText.propTypes = {
  children: PropTypes.node,
}

const IconSearch = ({ children, ...props }) => {
  return (
    <FaSearch {...props}>
      {children}
    </FaSearch>
  )
}

IconSearch.propTypes = {
  children: PropTypes.node,
}

const IconInbox = ({ children, ...props }) => {
  return (
    <MdOutlineEmail {...props}>
      {children}
    </MdOutlineEmail>
  )
}

IconInbox.propTypes = {
  children: PropTypes.node,
}

const IconInboxLight = ({ children, ...props }) => {
  return (
    <PiEnvelopeSimpleLight {...props}>
      {children}
    </PiEnvelopeSimpleLight>
  )
}

IconInboxLight.propTypes = {
  children: PropTypes.node,
}

const IconVoltar = ({ children, ...props }) => {
  return (
    <FiChevronLeft {...props}>
      {children}
    </FiChevronLeft>
  )
}

IconVoltar.propTypes = {
  children: PropTypes.node,
}

const IconBrinquedo = ({ children, ...props }) => {
  return (
    <PiBabyBold {...props}>
      {children}
    </PiBabyBold>
  )
}

IconBrinquedo.propTypes = {
  children: PropTypes.node,
}

const IconNext = ({ children, ...props }) => {
  return (
    <GrNext {...props}>
      {children}
    </GrNext>
  )
}

IconNext.propTypes = {
  children: PropTypes.node,
}

const IconPrevious = ({ children, ...props }) => {
  return (
    <GrPrevious {...props}>
      {children}
    </GrPrevious>
  )
}

IconPrevious.propTypes = {
  children: PropTypes.node,
}

const IconMenu = ({ children, ...props }) => {
  return (
    <HiMenu {...props}>
      {children}
    </HiMenu>
  )
}

IconMenu.propTypes = {
  children: PropTypes.node,
}

const IconInfo = ({ children, ...props }) => {
  return (
    <FaInfo {...props}>
      {children}
    </FaInfo>
  )
}

IconInfo.propTypes = {
  children: PropTypes.node,
}

const IconDownload = ({ children, ...props }) => {
  return (
    <FaDownload {...props}>
      {children}
    </FaDownload>
  )
}

IconDownload.propTypes = {
  children: PropTypes.node,
}

const IconGarage = ({ children, ...props }) => {
  return (
    <GiHomeGarage {...props}>
      {children}
    </GiHomeGarage>
  )
}

IconGarage.propTypes = {
  children: PropTypes.node,
}

const IconSnow = ({ children, ...props }) => {
  return (
    <BsSnow {...props}>
      {children}
    </BsSnow>
  )
}

IconSnow.propTypes = {
  children: PropTypes.node,
}

const IconForbidden = ({ children, ...props }) => {
  return (
    <MdOutlineDoNotDisturb {...props}>
      {children}
    </MdOutlineDoNotDisturb>
  )
}

IconForbidden.propTypes = {
  children: PropTypes.node,
}

const IconSchedule = ({ children, ...props }) => {
  return (
    <AiOutlineSchedule {...props}>
      {children}
    </AiOutlineSchedule>
  )
}

IconSchedule.propTypes = {
  children: PropTypes.node,
}

const IconCheck = ({ children, ...props }) => {
  return (
    <FaRegCheckCircle {...props}>
      {children}
    </FaRegCheckCircle>
  )
}

IconCheck.propTypes = {
  children: PropTypes.node,
}

const IconUnCheck = ({ children, ...props }) => {
  return (
    <FaRegCircleXmark {...props}>
      {children}
    </FaRegCircleXmark>
  )
}

IconUnCheck.propTypes = {
  children: PropTypes.node,
}

const IconDoor = ({ children, ...props }) => {
  return (
    <FaDoorClosed {...props}>
      {children}
    </FaDoorClosed>
  )
}

IconDoor.propTypes = {
  children: PropTypes.node,
}

export {
  IconHelp,
  IconEdit,
  IconDelete,
  IconOnOff,
  IconCamera,
  IconNotificacao,
  IconNotificacaoOff,
  IconDown,
  IconDashboard,
  IconSettings,
  IconUser,
  IconUsers,
  IconEye,
  IconEyeSlash,
  IconRobot,
  IconBook,
  IconHeadset,
  IconAlexa,
  IconWrite,
  IconLogout,
  IconPassword,
  IconArrowUp,
  IconArrowDown,
  IconDot,
  IconDotFilled,
  IconReply,
  IconHashtag,
  IconSave,
  IconCancel,
  IconExpand,
  IconCollapse,
  IconCopy,
  IconUpload,
  IconFollow,
  IconUnfollow,
  IconFollowing,
  IconFacebook,
  IconTwitter,
  IconLinkedin,
  IconWhatsapp,
  IconLink,
  IconWeb,
  IconZerarFoto,
  IconVideo,
  IconUploadVideo,
  IconSidebarOpen,
  IconSidebarClose,
  IconClose,
  IconUploadImage,
  IconArticle,
  IconVerticalDots,
  IconClear,
  IconPatinho,
  IconFixed,
  IconText,
  IconSearch,
  IconInbox,
  IconInboxLight,
  IconVoltar,
  IconBrinquedo,
  IconPrevious,
  IconNext,
  IconMenu,
  IconInfo,
  IconDownload,
  IconGarage,
  IconSnow,
  IconForbidden,
  IconSchedule,
  IconCheck,
  IconUnCheck,
  IconDoor,
}
