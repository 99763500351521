/*
 ./index.jsx
 */
import React, { useState, Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { CheckToken } from './utils'
import Skelet from './components/Common/Skelet'
import VerifyAuth from './components/Common/VerifyAuth'
import './index.css'

// AppContext defines the GlobalContext of the website
// state shared between all the pages
import AppContext, { GlobalContext } from './contexts/AppContext'

import Home from './components/Home'

const Header = lazy(() => import('./components/Header'))
const Footer = lazy(() => import('./components/Footer'))
const Toast = lazy(() => import('./components/Common/Toast'))
const ScrollToTop = lazy(() => import('./components/Common/ScrollToTop'))

// import of Usuario
const Cadastro = lazy(() => import('./components/Usuario/Cadastro'))
const Confirmar = lazy(() => import('./components/Usuario/Confirmar'))
const Login = lazy(() => import('./components/Usuario/Login'))
const Logout = lazy(() => import('./components/Usuario/Logout'))
const Recuperar = lazy(() => import('./components/Usuario/Recuperar'))
const Reenviar = lazy(() => import('./components/Usuario/Reenviar'))
const ConfirmarCodigo = lazy(() => import('./components/Usuario/Recuperar/Confirmar'))
const NotFound = lazy(() => import('./components/Common/NotFound'))

const Adesao = lazy(() => import('./components/Adesao'))
// const Pagamento = lazy(() => import('./components/Adesao/Pagamento'))
// const Obrigado = lazy(() => import('./components/Adesao/Obrigado'))
// const Erro = lazy(() => import('./components/Adesao/Erro'))
const Sobre = lazy(() => import('./components/Sobre'))
const Contato = lazy(() => import('./components/Contato'))
const PoliticaPrivacidade = lazy(() => import('./components/PoliticaPrivacidade'))
const TermosDeUso = lazy(() => import('./components/TermosDeUso'))
const Empresa = lazy(() => import('./components/Empresa'))
const Faq = lazy(() => import('./components/Faq'))

// import of individual components/pages of Dashboard (User)
const User = lazy(() => import('./components/Dashboard/User'))
const ChangePassword = lazy(() => import('./components/Dashboard/User/ChangePassword'))
const DeleteUser = lazy(() => import('./components/Dashboard/User/DeleteUser'))

// import of individual components/pages of Dashboard (Admin)
const AdminLogs = lazy(() => import('./components/Dashboard/Admin/Logs'))
const AdminUsers = lazy(() => import('./components/Dashboard/Admin/Users'))
const AdminUserEdit = lazy(() => import('./components/Dashboard/Admin/Users/Editar'))
const AdminUserSchedule = lazy(() => import('./components/Dashboard/Admin/Users/Schedule'))
const AdminUserChangePassword = lazy(() => import('./components/Dashboard/Admin/Users/ChangePassword'))
const AdminUserDelete = lazy(() => import('./components/Dashboard/Admin/Users/DeleteUser'))
const AdminUserCreate = lazy(() => import('./components/Dashboard/Admin/Users/CreateUser'))

const Loading = () => {
  return (
    <div className="loading">
      <Skelet count={1} style={{ maxWidth: '768px', height: '250px' }}/>
      <Skelet count={8} style={{ maxWidth: '768px', height: '50px' }}/>
    </div>
  )
}

const App = () => {
  const [isAuth, setIsAuth] = useState(CheckToken())
  const [isOpen, setIsOpen] = useState(false)

  return (
    <AppContext>
      <BrowserRouter>
        <Header 
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <GlobalContext.Consumer>
              {({ appData }) => {
                return (
                  <div className="main-container" data-has-sidebar={appData.hasSidebar ?? false}>
                    <VerifyAuth isAuth={isAuth} setIsAuth={setIsAuth} />
                    <Suspense fallback={<Loading />}>
                      <Routes>
                        {/* Routes of the website */}
                        <Route path="/" element={<Home/>} />
                        <Route path="/politica-de-privacidade" element={<PoliticaPrivacidade/>} />
                        <Route path="/termos-de-uso" element={<TermosDeUso/>} />
                        <Route path="/sobre" element={<Sobre/>} />
                        <Route path="/contato" element={<Contato/>} />
                        <Route path="/empresa" element={<Empresa/>} />
                        <Route path="/faq" element={<Faq/>} />
                        <Route path="/adesao/:plano_id?" element={<Adesao/>} />
                        { /*
                        <Route path="/adesao/pagamento" element={<Pagamento/>} />
                        <Route path="/adesao/obrigado" element={<Obrigado/>} />
                        <Route path="/adesao/erro" element={<Erro/>} />
                        */ }

                        {/* Routes of Login */}
                        <Route path="/cadastro" element={<Cadastro/>} />
                        <Route path="/confirmar-cadastro/:hash?" element={<Confirmar/>} />
                        <Route path="/login/:message?" element={<Login/>} />
                        <Route path="/logout" element={<Logout/>} />
                        <Route path="/recuperar-senha/:hash?" element={<Recuperar/>} />
                        <Route path="/confirmar-codigo" element={<ConfirmarCodigo />} />
                        <Route path="/reenviar-codigo/:email?" element={<Reenviar/>} />

                        {/* Routes of Dashboard/User - Private */}
                        <Route path="/dashboard/user" element={<User/>} />
                        <Route path="/dashboard/user/changePassword" element={<ChangePassword/>} />
                        <Route path="/dashboard/user/deleteUser" element={<DeleteUser/>} />

                        {/* Routes of Dashboard/admin - Private */}
                        <Route path="/dashboard/admin/logs" element={<AdminLogs/>} />
                        <Route path="/dashboard/admin/users" element={<AdminUsers/>} />
                        <Route path="/dashboard/admin/users/editar/:id" element={<AdminUserEdit/>} />
                        <Route path="/dashboard/admin/users/mudar-senha/:id" element={<AdminUserChangePassword/>} />
                        <Route path="/dashboard/admin/users/deletar/:id" element={<AdminUserDelete/>} />
                        <Route path="/dashboard/admin/users/criar" element={<AdminUserCreate/>} />
                        <Route path="/dashboard/admin/users/schedule/:id" element={<AdminUserSchedule/>} />

                        {/* rota padrão 404: */}
                        <Route path="/404" element={<NotFound/>} />
                        <Route path="*" element={<NotFound/>} />
                      </Routes>
                    </Suspense>
                  </div>
                )}
              }
            </GlobalContext.Consumer>
          <Toast />
          <ScrollToTop />
        </div>
        <Footer/>
      </BrowserRouter>
    </AppContext>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App/>)

