import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CheckToken, atualizarToken } from '../../utils'

import { GlobalContext } from '../../contexts/AppContext'

// VerifyAuth is a component that verifies if the user is authenticated
// if not, it redirects to the login page
const VerifyAuth = ({isAuth, setIsAuth}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { appData, setAppData } = useContext(GlobalContext)
  const staticData = appData

  useEffect(() => {
    // armazenar ultima pagina visitada em appData
    const pathName = window.location.pathname

    if (!pathName.match(/\/(login|logout|recuperar|reenviar|cadastro|confirmar)/)) {
      setAppData((prev) => {
        return {
          ...prev,
          lastPage: pathName
        }
      })
    }
  }, [setAppData, location])

  // useEffect para verificar se o usuario esta autenticado
  useEffect(() => {
    // authRoutesExpr tem a lista de páginas que precisam de autenticação
    // usar regex para verificar se o pathname corresponde a alguma rota
    const authRoutesExpr = [
      '/dashboard*',
      '/',
    ]
    const pathName = location.pathname
    const validToken = CheckToken()
    setIsAuth(validToken)

    if (!validToken && !pathName.match(/\/(login|logout|recuperar|reenviar|cadastro|confirmar|termos|politica|contato|sobre)/)) {
      // verificar se o pathname match com alguma rota de authRoutesExpr
      authRoutesExpr.forEach((route) => {
        if (pathName.match(route)) {
          // redirecionar para a pagina de login
          navigate('/login/Você precisa estar logado para acessar o aplicativo')
        }
      })
    } else {
      // atualizar token
      if ( pathName !== '/logout' ) {
        atualizarToken()
          .then((res) => {
            if (res?.id && !staticData.userData?.id) {
              // atualizar appData.userData
              setAppData((prev) => {
                return {
                  ...prev,
                  userData: {
                    ...prev.userData,
                    ...res
                  }
                }
              })
            }
          })
      }
    }
  }, [isAuth, setIsAuth, location, navigate, setAppData, staticData.userData?.id])

  // this one check if the user is a condominium admin
  useEffect(() => {
    const isAdmin = appData.userData?.condominios?.find(condominio => condominio.id === parseInt(appData.userData.condominio_selected_id))?.is_admin === 1

    const adminRoutesExpr = [
      '/dashboard/admin',
    ]
    const pathName = location.pathname

    if (!isAdmin && pathName.match(adminRoutesExpr)) {
      navigate('/')
    }
  }, [appData.userData?.condominio_selected_id, appData.userData?.condominios, location, navigate])

}

export default VerifyAuth
