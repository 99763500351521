/*
 * ./utils/index.js
 */
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'

// biblioteca para formatar datas
import moment from 'moment'
import 'moment/locale/pt-br'

export const postAPIData = async (path, body = {}, send_header = true) => {
  const url = site_config.API_URL + path
  // enviar headers somente se send_header for true
  const headers = (send_header) ? {
    'Content-Type': 'application/json',
    } : {}

  const res = await axios.post(url, body, {
    headers
  })
  return res.data
}

// pegar host da url
export const getHost = () => {
  const url = window.location.href
  const host = url.split('/')[2]
  return host
}

export const API_URL = getHost().match(/localhost/) ? 'https://localhost:3005/api' : 'https://api.automatizando.dev/api'
// export const API_URL = getHost().match(/192.168/) ? 'https://192.168.0.120:3005/api' : 'https://api.automatizando.dev/api'
// export const API_URL = 'https://api.automatizando.dev/api'
export const STATIC_URL = 'https://static.automatizando.dev'

export const site_config = {
  API_URL: API_URL,
  API_LOGIN_URL: API_URL + '/login',
  API_CONFIRMAR_CADASTRO_URL: API_URL + '/confirmar_cadastro',
  API_CADASTRAR_USUARIO_URL: API_URL + '/cadastrar_usuario',
  API_REENVIAR_CODIGO_URL: API_URL + '/reenviar_codigo',
  REACT_APP_DASHBOARD_URL: '/dashboard'
}

// função para armazenar o token no localstorage
export const storeToken = async (data) => {
  if (!data || !data.token) {
    return
  }
  // localStorage.setItem('id', data?.userData?.id)
  localStorage.setItem('username', data?.userData?.username)
  localStorage.setItem('nome', data?.userData?.nome)
  localStorage.setItem('email', data?.userData?.email)
  localStorage.setItem('tipo', data?.userData?.tipo)
  localStorage.setItem('token', data?.token)
  localStorage.setItem('foto', data?.userData?.foto)
}

// função para remover o token do localstorage
export const RemoveToken = () => {
  localStorage.removeItem('username')
  localStorage.removeItem('nome')
  localStorage.removeItem('email')
  localStorage.removeItem('tipo')
  localStorage.removeItem('token')
  localStorage.removeItem('foto')
  localStorage.removeItem('condominio_selected_id')
}

// função para retornar o token do localstorage
// caso não exista, retorna null
export const GetToken = () => {
  try {
    const token = localStorage.getItem('token') || ''

    const decodedToken = ( token ) ? JSON.parse(atob(token.split('.')[1])) : null
    if (!decodedToken) {
      return false
    }

    if (decodedToken.exp * 1000 < Date.now()) {
      return false
    }
    decodedToken.nome = decodeURI(decodedToken.nome)
    return decodedToken
  } catch (error) {
    return false
  }
}

// verificar se token é válido
// retorna true se válido, false se inválido
// se inválido, remove o token do localstorage
export const CheckToken = () => {
  const token = GetToken()

  if (!token) {
    return false
  }
  return true
}

export const atualizarToken = async () => {
  const decodedToken = GetToken()
  const response = await fetch(site_config.API_URL + '/atualizar_token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: decodedToken.email,
      token: localStorage.getItem('token')
    })
  })
  const json = await response.json()
  json.nome = decodeURI(json.nome)

  if (json?.token) {
    localStorage.setItem('token', json.token)
  }
  return json
}

export const maskToken = (token) => {
  if (token === null || token === undefined || token === '')
    return token

  if (token.length < 5)
    return token

  return token
    .replace(/^(.{4})(.*)(.{4})$/, '$1' + '*'.repeat(token.length - 8) + '$3')
    // .replace(/(.{20})/g, '$1 ').trim() // adiciona espaços a cada 20 caracteres
}

export const validateOpenAIToken = async (token) => {
  try {
    const response = await fetch('https://api.openai.com/v1/engines/davinci/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        prompt: 'Quanto é 2+2?',
        max_tokens: 1
      })
    })
    const json = await response.json()
    return json.choices ? json.choices.length > 0 : false
  } catch (error) {
    return false
  }
}

export const formatDate = (dateString, format = 1) => {
  if (format === 1) {
    moment.locale('pt-br')
    return moment(dateString).fromNow()
  } else if (format === 2) {
    const date = new Date(dateString)
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }
    return new Intl.DateTimeFormat('pt-BR', options).format(date)
  } else {
    const date = new Date(dateString)
    const currentYear = new Date().getFullYear()
    const options = {
      day: '2-digit',
      month: 'long',
      year: date.getFullYear() === currentYear ? undefined : 'numeric'
    }
    return new Intl.DateTimeFormat('pt-BR', options).format(date)
  }
}

export const checkUsername = async (username) => {
  if (username.length < 3) {
    return false
  }

  try {
    const data = await postAPIData('/username_existe', { username })

    if (data.status === 200) {
      return true
    }
  } catch (error) {
    console.error('Error checking username:', error)
  }

  return false
}

export const checkEmail = async (email) => {
  if (email.length < 3) {
    return false
  }

  try {
    const data = await postAPIData('/email_existe', { email })

    if (data.status === 200) {
      return true
    }
  } catch (error) {
    console.error('Error checking email:', error)
  }

  return false
}

// lista com o tamanho de cada dispositivo
export const DeviceSize = {
  mobile: 768,
  tablet: 992,
  laptop: 1200,
  desktop: 1440
}

export const IsMobile = () => {
  return useMediaQuery({ maxWidth: DeviceSize.tablet })
}

// função para atualizar o appData.userData.posts e appData.posts
// ela é usada em Dashboard / Posts
export const updateUserPosts = (postData, setAppData) => {
  if (!postData && !postData?.id) {
    return
  }

  if (postData.id) {
    setAppData((prevState) => {
      // lista de posts global
      const updatedPosts = prevState.posts?.map((post) =>
        post.id === postData.id ? { ...post, ...postData } : post
      ) || [postData]

      // lista de posts do usuário
      const updatedUserPosts = prevState.userData?.posts?.map((post) =>
        post.id === postData.id ? postData : post
      ) || [postData]

      return {
        ...prevState,
        posts: updatedPosts,
        userData: {
          ...prevState.userData,
          posts: updatedUserPosts,
        },
      }
    })
  }
}

export const updateUserVideos = (videoData, setAppData) => {
  if (videoData.id) {
    setAppData((prevState) => {
      // lista de videos global
      const updatedVideo = prevState.videos?.map((video) =>
        video.id === videoData.id ? { ...video, ...videoData } : video
      ) || [videoData]

      // lista de videos do usuário
      const updatedUserVideo = prevState.userData?.videos?.map((video) =>
        video.id === videoData.id ? videoData : video
      ) || [videoData]

      return {
        ...prevState,
        videos: updatedVideo,
        userData: {
          ...prevState.userData,
          videos: updatedUserVideo,
        },
      }
    })
  }
}

export const updateUserAnexos = (anexoData, setAppData) => {
  if (anexoData.id) {
    setAppData((prevState) => {
      // lista de anexos global
      const updatedAnexo = prevState.anexos?.map((anexo) =>
        anexo.id === anexoData.id ? { ...anexo, ...anexoData } : anexo
      ) || [anexoData]

      // lista de anexos do usuário
      const updatedUserAnexo = prevState.userData?.anexos?.map((anexo) =>
        anexo.id === anexoData.id ? anexoData : anexo
      ) || [anexoData]

      return {
        ...prevState,
        anexos: updatedAnexo,
        userData: {
          ...prevState.userData,
          anexos: updatedUserAnexo,
        },
      }
    })
  }
}

export const checkPermission = (username, navigate) => {
  if (!username) {
    return
  }
  const token = GetToken()

  if (token.username !== username && token.tipo !== 'admin') {
    navigate('/')
  }
}

export const formatSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600).toString().padStart(2, '0')
  seconds %= 3600
  const minutes = Math.floor(seconds / 60).toString().padStart(2, '0')
  const secs = (seconds % 60).toString().padStart(2, '0')

  return `${hours}:${minutes}:${secs}`
}

export const getSelectedText = () => {
  let text = ""
  if (typeof window.getSelection !== "undefined") {
    text = window.getSelection().toString()
  } else if (typeof document.selection !== "undefined" && document.selection.type === "Text") {
    text = document.selection.createRange().text
  }
  return text
}

export const enviarNotificacao = async (body) => {
  const icon = 'https://automatizando.dev/favicon.ico'

  // Verifique se o navegador suporta notificações
  if (!("Notification" in window)) {
    console.log("Este navegador não suporta notificações de sistema")
  }

  // Verifique se a permissão já foi concedida
  else if (Notification.permission === "granted") {
    // console.log('Permissão já concedida')
    // Se tudo estiver ok, vamos criar uma notificação
    const notification = new Notification('Automatizando.dev', { body, icon })
    notification.onclick = () => {
      window.focus()
    }
  }

  // Caso contrário, precisamos pedir permissão ao usuário
  else if (Notification.permission !== 'denied') {
    console.log('Permissão não concedida')
    Notification.requestPermission(function (permission) {
      // Se a permissão for aceita, criamos uma notificação
      if (permission === "granted") {
        const notification = new Notification('Automatizando.dev', { body, icon })
        notification.onclick = () => {
          window.focus()
        }
      }
    })
  }

  // var notification = new Notification("Título da Notificação", {
    // body: "Corpo da Notificação",
    // icon: "url do ícone"
  // })
}

// função para calcular a quantidade de tokens do prompt
export const countTokens = (text) => {
  // Removemos espaços em branco extras
  const trimmedText = text.trim()

  // Definimos uma expressão regular para encontrar sílabas em uma palavra
  const syllableRegex = /(?:[^aeiouy]([aeiouy]|[aeiouy]($)))+/gi

  // Expressão regular para encontrar caracteres de pontuação
  const punctuationRegex = /[.,;!?(){}[\]"':]/g

  // Dividimos o texto em palavras
  const words = trimmedText.split(/\s+/)

  // Contamos o número de caracteres de pontuação
  const punctuationCount = (trimmedText.match(punctuationRegex) || []).length

  // Inicializamos o contador de tokens
  let tokenCount = 0

  // Iteramos sobre cada palavra
  for (const word of words) {
    // Contamos as sílabas na palavra
    const syllables = word.match(syllableRegex)
    const numSyllables = syllables ? syllables.length : 0

    // Somamos o número de sílabas mais 1 (para contar a palavra em si) ao contador de tokens
    tokenCount += numSyllables + 1
  }

  // Adicionamos ao contador de tokens o número de espaços em branco e caracteres de pontuação
  tokenCount += words.length - 1 + punctuationCount

  // Retornamos o total de tokens
  return tokenCount === 1 ? 0 : tokenCount
}

// formatar numeros em k, m etc
export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num % 1000000 === 0) ? (num / 1000000) + 'M' : (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num % 1000 === 0) ? (num / 1000) + 'k' : (num / 1000).toFixed(1) + 'k';
  } else {
    return num.toString();
  }
}

export const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export const isCondominiumAdmin = (userData = []) => {
  // userData.condominios is a array of objects
  
  if (!userData.id) {
    return false
  }

  return userData.condominios.filter(condominio => condominio.id === parseInt(userData.condominio_selected_id)).find(condominio => condominio.is_admin === 1)
}
