/*
 * ./components/Home/index.jsx
 */
import React, { useEffect, Suspense, useContext, useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { GlobalContext } from '../../contexts/AppContext'
import { postAPIData, isCondominiumAdmin } from '../../utils/'
import Skelet from '../Common/Skelet'
import { showConfirmAlert } from '../Common'
import { IconGarage, IconDoor, IconSnow, IconUser, IconCopy } from '../Common/Icons'
import NoCondominio from './NoCondominio'

const Loading = () => {
  return (
    <div className="loading">
      <Skelet count={1} style={{ maxWidth: '768px', height: '250px' }} />
      <Skelet count={8} style={{ maxWidth: '768px', height: '50px' }} />
    </div>
  )
}

const frases = [
  // frases motivacionais, sem gênero
  'Seja bem vindo, ',
  'Tenha um ótimo dia, ',
  'Sucesso e realizações, ',
  'Você está muito elegante hoje, ',
  'Você é incrível, ',
  'Você é um vencedor(a), ',
  'Um dia de cada vez, ',
  'Que a força esteja com você, ',
  'Uma energia positiva para você, ',
  'Já deu um sorriso hoje? ',
]

const icons = {
  '1': <IconGarage />,
  '2': <IconSnow />,
  '4': <IconDoor />,
}

const Home = (props) => {
  const { appData, setAppData } = useContext(GlobalContext)
  const [frase, setFrase] = useState(frases[Math.floor(Math.random() * frases.length)])
  const inativo = appData?.userData?.condominios?.find(condominio => condominio.id === parseInt(appData.userData.condominio_selected_id))?.ativo === 0 || false

  // mudar titulo da página
  useEffect(() => {
    document.title = 'Abrir Garagem - Automatizando.dev'

    // scroll to top smooth
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  // mudar frase motivacional
  useEffect(() => {
    const tmout = setTimeout(() => {
      setFrase(frases[Math.floor(Math.random() * frases.length)])
    }, 60000 * 10)
    return () => clearTimeout(tmout)
  }, [frase])

  // abrir garagem
  const handleBotaoGaragem = useCallback(async (deviceId, btnId) => {
    // desabilitar botão
    const btn = document.getElementById(btnId)
    btn.disabled = true

    // se chegou aqui, buscar dados do device no servidor
    const user_id = appData.userData.id

    try {
      const data = await postAPIData('/common/abrir-garagem', { user_id, device_id: deviceId })

      // habilitar botão 3 segundos depois
      setTimeout(() => {
        btn.disabled = false
      }
        , 3000)

      if (data.status !== 200) {
        console.log('erro ao abrir garagem', data)
        toast.error(data.message)
        return
      }

      toast.success(data.message)
    } catch(err) {
        console.log('erro ao abrir garagem', err)
        toast.error('Erro ao abrir garagem')
        // habilitar botão 3 segundos depois
        setTimeout(() => {
          btn.disabled = false
        }
          , 3000)
    }
  }, [appData.userData?.id])

  const handleCheckbox = async (device_id, checked, checkbox_id) => {
    const checkbox = document.getElementById(checkbox_id)
    checkbox.disabled = true

    const response = await postAPIData('/common/ativar-desativar-dispositivo', { id: device_id, is_active: checked })
    checkbox.disabled = false

    console.log('response', response)
    setAppData(prevState => {
      const newUserData = { ...prevState.userData }
      const condominioIndex = newUserData.condominios.findIndex(condominio => condominio.id === parseInt(appData.userData.condominio_selected_id))
      const deviceIndex = newUserData.condominios[condominioIndex].devices.findIndex(device => device.id === parseInt(device_id))
      newUserData.condominios[condominioIndex].devices[deviceIndex].is_active = checked ? 1 : 0
      return { ...prevState, userData: newUserData }
    })
  }



  return !appData?.userData?.id ? (
    <Loading />
  ) : appData?.userData?.condominios?.length === 0 ? (
    <NoCondominio />
  ) : (
      <Suspense fallback={<Loading />}>
        <section id="home">
          {inativo === true && (
          <div className="garage-warning">
            <p>Seu condomínio está inativo. Entre em contato com o <NavLink to="/contato">nosso suporte</NavLink> para ativá-lo.</p>
          </div>
        )}

        { 
          /*
           * for each appData.userData.condominios[selectedCondominio].devices, build a button:
           * - nome
           * - entidade will be sent to the handleBotaoGaragem function
           */
          appData.userData.condominios
            .find(condominio => condominio.id === parseInt(appData.userData.condominio_selected_id))?.devices
            .sort((a, b) => a.ordem - b.ordem)
            .map((device, index) => (
              <div className="garage-button-container" key={`device-${index}`}>
                <button
                  key={index}
                  id={`garage-button-${appData.userData.condominio_selected_id}-${index}`}
                  className="garage-button"
                  onClick={() => handleBotaoGaragem(device.id, `garage-button-${appData.userData.condominio_selected_id}-${index}`)}
                  disabled={
                    // check if the current user is active in the current condominium
                    !appData.userData.condominios.find(condominio => condominio.id === parseInt(appData.userData.condominio_selected_id))?.is_active
                      || device.is_active === 0 // check if the current device is active
                  }
                >
                  {icons[device.tipo]}
                  {device.nome}
                </button>
                {/* crie um checkbox para ativar/desativar o dispositivo */}
                {isCondominiumAdmin(appData.userData) && (
                  <div className="garage-button-checkbox">
                    <input
                      type="checkbox"
                      id={`garage-button-checkbox-${appData.userData.condominio_selected_id}-${index}`}
                      checked={device.is_active === 1}
                      onChange={() => 
                          showConfirmAlert(`Deseja realmente ${device.is_active === 1 ? 'desativar' : 'ativar'} este dispositivo?`, () => handleCheckbox(device.id, !device.is_active, `garage-button-checkbox-${appData.userData.condominio_selected_id}-${index}`))}
                    />

                    <label htmlFor={`garage-button-checkbox-${appData.userData.condominio_selected_id}-${index}`}>
                      Habilitado
                    </label>

                  </div>
                )}
                { /* avisar se o dispositivo está inativo caso não seja admin */}
                {!isCondominiumAdmin(appData.userData) && device.is_active === 0 && (
                  <span style={{ color: 'red' }}>Desabilitado pelo administrador</span>
                )}
              </div>
            ))
        }

        <div className="garage-name">
          {frase}
          {
            // first name only
            appData.userData?.nome?.split(' ')[0] + '!'
          }
        </div>

        {isCondominiumAdmin(appData.userData) && (
          <div className="garage-actions">
            <NavLink to="/dashboard/admin/users">
              <div className="garage-admin-button">
                <IconUser />
                Gerenciar usuários
              </div>
            </NavLink>

            <NavLink to="/dashboard/admin/logs">
              <div className="garage-admin-button">
                <IconCopy />
                Logs de acesso
              </div>
            </NavLink>
          </div>
        )}

      </section>
    </Suspense>
  )
}

Home.propTypes = {
  token: PropTypes.string
}

Home.defaultProps = {
  token: null
}

export default Home
